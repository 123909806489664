import { isDefined } from "@clipboard-health/util-ts";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { useToast } from "@src/appV2/lib";
import { isArray, isEmpty } from "lodash";

import { useGetRequirementStatus } from "../../Documents/api/useGetRequirementStatus";
import {
  type DocumentRequirementType,
  DocumentRequirementType as DocumentRequirementTypeValues,
} from "../../Documents/types";
import { useGetDocument } from "../api/useGetDocument";
import { useGetRejectedDocumentNotification } from "../api/useGetRejectedDocumentNotification";
import { useGetRequirementExpiry } from "../api/useGetRequirementExpiry";

interface DocumentProperties {
  requirementId?: string;
  requirementType?: DocumentRequirementType;
  facilityId?: string;
}

export function useDocumentProperties(props: DocumentProperties) {
  const { requirementId, requirementType, facilityId } = props;

  const { showErrorToast } = useToast();
  const { data: agentProfile, isLoading: isLoadingAgentProfile } = useAgentProfile();

  const hcpId = agentProfile?.userId;

  const isRejectedDocument = requirementType === DocumentRequirementTypeValues.REJECTED;

  const getRejectedDocumentNotificationResponse = useGetRejectedDocumentNotification(
    {
      hcpId,
      requirementId,
    },
    {
      select: (data) => {
        return data.data.rejectedDocument?.notification;
      },
      enabled: isDefined(requirementId) && isDefined(hcpId) && isRejectedDocument,
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );

  const getDocumentResponse = useGetDocument(
    {
      hcpId,
      requirementId,
    },
    {
      select: (data) => {
        const {
          data: { documentList },
        } = data;

        if (isArray(documentList) && !isEmpty(documentList)) {
          const document = documentList[0];
          return { documentId: document._id, subtype: document.subType ?? undefined };
        }

        return {};
      },
      enabled: isDefined(requirementId) && isDefined(hcpId),
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );

  const getRequirementExpiryResponse = useGetRequirementExpiry(
    {
      hcpId,
      requirementId,
    },
    {
      select: (data) => {
        const {
          data: { hcpRequirementList },
        } = data;

        if (!isArray(hcpRequirementList) || isEmpty(hcpRequirementList)) {
          return {};
        }

        const [hcpRequirement] = hcpRequirementList;

        return {
          expiry: hcpRequirement?.expiry,
          expiryType: hcpRequirement?.baseRequirement?.expiryType,
        };
      },
      enabled: isDefined(requirementId) && isDefined(hcpId),
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );

  const getRequirementStatusResponse = useGetRequirementStatus(
    {
      workerId: hcpId,
      ...(isDefined(facilityId) ? { facilitiesId: [facilityId] } : {}),
    },
    {
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
      enabled: isDefined(hcpId),
    }
  );
  const selectedRequirement = isDefined(requirementId)
    ? getRequirementStatusResponse.hcpRequirementStatus.requirements[requirementId]
    : undefined;

  return {
    documentProperties: {
      selectedRequirement,
      expiry: getRequirementExpiryResponse.data?.expiry,
      expiryType: getRequirementExpiryResponse.data?.expiryType,
      documentId: getDocumentResponse.data?.documentId,
      submittedDocumentSubtype: getDocumentResponse.data?.subtype,
      rejectedDocumentNotification: getRejectedDocumentNotificationResponse.data,
    },
    isLoading:
      isLoadingAgentProfile ||
      getRequirementStatusResponse.isLoading ||
      getRequirementExpiryResponse.isLoading ||
      getDocumentResponse.isLoading ||
      (getRejectedDocumentNotificationResponse.isLoading &&
        getRejectedDocumentNotificationResponse.fetchStatus !== "idle"),
  };
}
